<template lang="pug">
.bg
  .container
    .title 用户登录
    .content
      el-input(
        v-model="username"
        placeholder="手机号码"
        style="margin-bottom: 20px"
      )
      el-input(v-model="password" placeholder="密码" show-password)
      el-row
        el-button.login(type="primary" @click="login" :loading="loading") 登录
</template>

<script>
import request from '@/utils/request';
import { USER_INFO, PEMISSION } from '@/utils/const';

export default {
  data() {
    return {
      username: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    login() {
      if (!this.username || !this.password) {
        this.$message.warning('请输入手机号和密码');
        return;
      }
      this.loading = true;
      request({
        method: 'post',
        url: '/manager/login',
        data: {
          username: this.username,
          password: this.password,
        },
      })
        .then((res) => {
          if (res.code === 1000) {
            const userInfo = res.data;
            // 设置7天过期
            userInfo.expire = new Date().valueOf() + (1000 * 3600 * 24 * 7);
            localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
            localStorage.setItem(PEMISSION, JSON.stringify(res.data.nodes));

            this.$message.success(res.msg);

            this.$router.replace({
              name: 'default',
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    overflow: hidden;
    width: 350px;

    .title {
      line-height: 50px;
      color: white;
      text-align: center;
      border-bottom: 1px solid white;
      font-weight: bold;
    }

    .content {
      padding: 20px 40px;

      .login {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
</style>
